<template>
  <div>
    <!-- Filter -->
    <b-card
      footer-tag="footer"
      class="mb-1 card-filter-container"
    >
      <b-row>
        <b-col
          sm="12"
          md="3"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group>
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('date_from')"
              />
            </b-input-group-prepend>
            <b-form-datepicker
              v-model="filter.startDate"
              :max="filter.endDate"
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="3"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group>
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('time_from')"
              />
            </b-input-group-prepend>
            <b-form-timepicker
              v-model="filter.startTime"
              show-seconds
              locale="en"
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="3"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group>
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('date_to')"
              />
            </b-input-group-prepend>
            <b-form-datepicker
              v-model="filter.endDate"
              :min="filter.startDate"
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="3"
          lg="3"
          class="card-filter-item"
        >
          <b-input-group>
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('time_to')"
              />
            </b-input-group-prepend>
            <b-form-timepicker
              v-model="filter.endTime"
              show-seconds
              locale="en"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="card-filter-item mt-1"
        >
          <b-button
            variant="primary float-right"
            @click="loadData"
            v-text="$t('search')"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col>
        <b-card class="text-center">
          <h2 v-text="new Intl.NumberFormat('fil-PH', { minimumFractionDigits: 2 }).format(report.total)" />
          <span v-text="$t('total_jackpot')" />
        </b-card>
      </b-col>
      <b-col>
        <b-card class="text-center">
          <h2 v-text="new Intl.NumberFormat('fil-PH', { minimumFractionDigits: 2 }).format(report.major)" />
          <span v-text="$t('major_jackpot')" />
        </b-card>
      </b-col>
      <b-col>
        <b-card class="text-center">
          <h2
            v-text="new Intl.NumberFormat('fil-PH', { minimumFractionDigits: 2 }).format(report.minor)"
          />
          <span v-text="$t('minor_jackpot')" />
        </b-card>
      </b-col>
      <b-col>
        <b-card class="text-center">
          <h2
            v-text="new Intl.NumberFormat('fil-PH', { minimumFractionDigits: 2 }).format(report.reserve)"
          />
          <span v-text="$t('reserve_jackpot')" />
        </b-card>
      </b-col>
      <b-col>
        <b-card class="text-center">
          <h2
            v-text="new Intl.NumberFormat('fil-PH', { minimumFractionDigits: 2 }).format(report.hits)"
          />
          <span v-text="$t('hit_jackpot')" />
        </b-card>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-overlay
      :show="loading"
      opacity="0.80"
      rounded="sm"
    >
      <div class="card">
        <div class="card-body">
          <div class="vgt-wrap">
            <div class="vgt-inner-wrap">
              <div class="vgt-responsive game-history-table">
                <table class="vgt-table bordered">
                  <thead>
                    <tr>
                      <th class="vgt-left-align">
                        <span v-text="$t('idx')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('round_id')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('type')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('hit_amount')" />
                      </th>
                      <th class="vgt-left-align">
                        <span v-text="$t('created_date')" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in items"
                      :key="item.id"
                    >
                      <td class="vgt-left-align">
                        <span v-text="item.idx" />
                      </td>
                      <td class="vgt-left-align">
                        <span v-text="item.round_id" />
                      </td>
                      <td class="vgt-left-align">
                        <span v-text="$t(item.type)" />
                      </td>
                      <td class="vgt-left-align">
                        <span v-text="item.hit_amount" />
                      </td>
                      <td class="vgt-left-align">
                        <span v-text="item.created_date" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <b-pagination
            v-model="filter.page"
            :per-page="perPage"
            hide-goto-end-buttons
            :total-rows="totalPage"
            align="center"
            @change="loadData"
          />
        </div>
      </div>
    </b-overlay>

  </div>
</template>

<script>

import {
  BOverlay,
  BCard,
  BPagination,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BFormDatepicker,
  BFormTimepicker,
  BButton,
} from 'bootstrap-vue'

import Jackpot from '@/models/Jackpot'

import 'vue-good-table/dist/vue-good-table.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { EventBus } from '../../../eventbus'

export default {
  name: 'ReportGameHistory',
  components: {
    BOverlay,
    BCard,
    BPagination,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BFormDatepicker,
    BFormTimepicker,
    BButton,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      agentId: null,
      items: [],
      loading: true,
      report: {
        total: 0,
        major: 0,
        minor: 0,
        reserve: 0,
        hits: 0,
      },
      perPage: 10,
      totalPage: 0,
      filter: {
        page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1,
        startDate: typeof this.$route.query.startDate !== 'undefined' ? this.$route.query.startDate : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() - 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() - 86400000).getDate())}`,
        endDate: typeof this.$route.query.endDate !== 'undefined' ? this.$route.query.endDate : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() + 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() + 86400000).getDate())}`,
        startTime: typeof this.$route.query.startTime !== 'undefined' ? this.$route.query.startTime : '00:00:00',
        endTime: typeof this.$route.query.endTime !== 'undefined' ? this.$route.query.endTime : '23:59:59',
      },
      currentFilter: {
        page: 1,
        startDate: typeof this.$route.query.startDate !== 'undefined' ? this.$route.query.startDate : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() - 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() - 86400000).getDate())}`,
        endDate: typeof this.$route.query.endDate !== 'undefined' ? this.$route.query.endDate : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() + 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() + 86400000).getDate())}`,
        startTime: typeof this.$route.query.startTime !== 'undefined' ? this.$route.query.startTime : '00:00:00',
        endTime: typeof this.$route.query.endTime !== 'undefined' ? this.$route.query.endTime : '23:59:59',
      },
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData(page = 1) {
      this.loading = true
      const searchParams = {}
      this.filter.page = page
      Object.keys(this.filter).forEach(e => {
        if (typeof this.filter[e] === 'number') {
          if (this.filter[e] > 0) {
            searchParams[e] = this.filter[e]
          }
        } else if (typeof this.filter[e] === 'string') {
          if (this.filter[e] !== null && this.filter[e].length > 0) {
            searchParams[e] = this.filter[e]
          }
        }
      })

      this.$router.replace({ query: searchParams })
      const response = await Jackpot.lists(searchParams)
      if (response.data.status) {
        this.items = response.data.data
        this.report = response.data.meta.report
        this.totalPage = response.data.meta.pagination.total
        this.perPage = response.data.meta.pagination.per_page
        this.agentId = response.data.meta.agent.id
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: this.$t(response.data.message),
          },
        }, {
          position: 'top-center',
        })
      }
      EventBus.$emit('getMyUpdatedUserData')
      this.loading = false
    },
  },
}
</script>
